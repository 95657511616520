import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  rooms: [],
  themes: [] // [ { rid: '', themes: [] }, ... ]
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 自分が入っている部屋情報の一覧
   */
  rooms: state => state.rooms,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} rid 部屋情報のドキュメントID
   * @return {Object} 部屋情報
   */
  room: state => rid => state.rooms.filter(room => room.rid === rid).length === 1 ? state.rooms.filter(room => room.rid === rid)[0] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Strig} rid 部屋情報のドキュメントID
   * @return {Object[]} 部屋情報に紐づくテーマ一覧
   */
  themes: state => rid => state.themes.filter(theme => theme.rid === rid).length === 1 ? state.themes.filter(theme => theme.rid === rid)[0].themes : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Strig} rid 部屋情報のドキュメントID
   * @param {Strig} tid テーマ情報のドキュメントID
   * @return {Object[]} 部屋情報に紐づくテーマ一覧
   */
  theme: state => rid => tid =>
    state.themes.filter(theme => theme.rid === rid).length !== 1 ? null :
      state.themes.filter(theme => theme.rid === rid)[0].themes.filter(theme => theme.tid === tid).length !== 1 ? null :
        state.themes.filter(theme => theme.rid === rid)[0].themes.filter(theme => theme.tid === tid)[0]
}

const mutations = {
  /**
   * stateに自分が入っている部屋情報の一覧を格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} rooms 部屋情報の一覧
   */
  setRooms: (state, rooms) => {
    state.rooms = rooms
  },
  /**
   * stateに取得したテーマ情報を格納する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.rid 部屋情報のドキュメントID
   * @param {Object[]} payload.themes テーマ一覧
   */
  setThemes: (state, payload) => {
    // 既に存在するかの確認
    const existRID = state.themes.filter(theme => theme.rid === payload.rid).length === 1

    // 既存のテーマを取得
    // 既に存在する場合は、それ以外を取得
    const themes = existRID ? state.themes.filter(theme => theme.rid !== payload.rid) : state.themes

    // テーマの更新
    themes.push({ rid: payload.rid, themes: payload.themes })
    state.themes = themes
  }
}

const actions = {
  /**
   * 指定ドキュメントIDの部屋情報を取得
   * @param {String} rid ドキュメントID
   * @return {Object} 部屋情報
   */
  getRoom ({ commit }, rid) {
    return new Promise(resolve => {
      firestore.collection('rooms').doc(rid).get().then(doc => {
        const room = doc.exists ? doc.data() : null
        return resolve(room)
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 自分が入っている部屋情報の一覧を取得
   * @param {String} uid ユーザーID
   * @return {Object[]} 自分の入っている部屋情報の一覧
   */
  getRooms ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('rooms').where('members', 'array-contains', uid).orderBy('createdAt', 'desc').get().then(snapshot => {
        const rooms = []
        snapshot.forEach(doc => {
          rooms.push(Object.assign(doc.data(), { rid: doc.id }))
        })

        commit('setRooms', rooms)
        return resolve(rooms)
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 部屋情報に紐づくテーマ一覧を取得
   * @param {String} rid 取得したいテーマの部屋のドキュメントID
   * @return {void}
   */
  getThemes ({ commit }, rid) {
    return new Promise(resolve => {
      firestore.collection('rooms').doc(rid).collection('themes').orderBy('createdAt', 'desc').get().then(snapshot => {
        const themes = []
        snapshot.forEach(doc => {
          themes.push(Object.assign(doc.data(), { tid: doc.id }))
        })
        if (themes.length > 0) commit('setThemes', { rid: rid, themes: themes })
        return resolve()
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /** */
  /**
   * 部屋情報とそれに付随する情報の作成
   * @param {Object} payload 引数
   * @param {String} paylod.uid 作成者のユーザーID
   * @param {String} payload.room.name 部屋の名前
   * @param {Number} payload.room.pin 部屋のPIN（整数4桁）
   * @return {String} 部屋のドキュメントID
   */
  addRoom ({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      // 部屋の作成
      dispatch('createRoom', {
        uid: payload.uid,
        room: payload.room
      }).then(rid => {
        // テーマの追加
        dispatch('addTheme', {
          rid: rid,
          theme: payload.theme
        }).then(() => {
          return resolve(rid)
        }).catch(() => {
          commit('setProcessing', false, { root: true })
          router.push({ name: 'error' })
        })
      })
    })
  },
  /**
   * 部屋にテーマを追加する
   * @param {Object} payload 引数
   * @param {String} payload.rid 追加したい部屋のドキュメントID
   * @param {String} payload.theme.name 追加したいテーマの名前
   * @param {String} payload.theme.category 追加したいテーマのカテゴリー
   * @return {void}
   */
  addTheme ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('rooms').doc(payload.rid).collection('themes').add({
        name: payload.theme.name,
        category: payload.theme.category,
        createdAt: new Date(),
        updatedAt: new Date()
      }).then(() => {
        return resolve()
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 部屋にテーマを追加する
   * @param {Object} payload 引数
   * @param {String} payload.rid 部屋のドキュメントID
   * @param {String} payload.tid テーマのドキュメントID
   * @param {String} payload.did 追加したい欲求のドキュメントID
   * @return {void}
   */
  addDesire ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('rooms').doc(payload.rid).collection('themes').doc(payload.tid).collection('dids').doc(payload.did).set({
        createdAt: new Date(),
        isDeleted: false
      }).then(() => {
        return resolve()
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 部屋情報の作成
   * @param {Object} payload 引数
   * @param {String} paylod.uid 作成者のユーザーID
   * @param {String} payload.room.name 部屋の名前
   * @param {Number} payload.room.pin 部屋のPIN（整数4桁）
   * @return {String} 部屋のドキュメントID
   */
  createRoom ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('rooms').add({
        name: payload.room.name,
        owner: payload.uid,
        pin: payload.room.pin,
        members: [payload.uid],
        createdAt: new Date(),
        updatedAt: new Date()
      }).then(doc => {
        return resolve(doc.id)
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 部屋情報の更新
   * @param {Object} payload 引数
   * @param {String} payload.rid 部屋のドキュメントID
   * @param {Object} payload.param 更新する内容
   * @return {void}
   */
  updateRoom ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('rooms').doc(payload.rid).update(payload.param).then(() => {
        return resolve()
      }).catch(() => {
        commit('setProcessing', false, { root: true })
        router.push({ name: 'error' })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
