import { firestore } from '@/firebase'

const state = {
  categories: [],
  titles: {}
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 欲求カテゴリー情報
   */
  categories: state => state.categories,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid カテゴリーのドキュメントID
   * @return {String[]} 欲求カテゴリー情報
   */
  titles: state => cid => state.titles[cid]
}

const mutations = {
  /**
   * stateに欲求カテゴリー情報を格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} categories 欲求カテゴリー情報
   */
  setCategories: (state, categories) => {
    state.categories = categories
  },
  /**
   * stateに格納されている欲求カテゴリー情報を削除する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid カテゴリーのドキュメントID
   */
  delCategory: (state, cid) => {
    state.categories = state.categories.filter(category => category.cid !== cid)
  },
  /**
   * stateに欲求カテゴリーに紐づく登録名情報を格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid 欲求カテゴリーのドキュメントID
   * @param {String[]} payload.titles 登録名一覧
   */
  setTitles: (state, payload) => {
    state.titles[payload.cid] = payload.titles
  }
}

const actions = {
  /**
   * 全欲求カテゴリー情報の取得
   * @return {Object[]} カテゴリーの一覧
   */
  getCategories ({ commit }) {
    return new Promise(resolve => {
      firestore.collection('categories')
        .get()
        .then(snapshot => {
          const categories = []
          snapshot.forEach(doc => {
            categories.push(Object.assign(doc.data(), { cid: doc.id }))
          })
          categories.push({ cid: 'other', name: 'その他（自由にタイトル記入可能）' })
          commit('setCategories', categories)
          return resolve(categories)
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 全欲求カテゴリーに紐づく名前情報の取得
   * @param {String} cid カテゴリーのドキュメントID
   * @return {void}
   */
  getTitles ({ commit }, cid) {
    return new Promise(resolve => {
      firestore.collection('categories')
        .doc(cid)
        .collection('titles')
        .get()
        .then(snapshot => {
          const titles = []
          snapshot.forEach(doc => {
            titles.push(doc.data().name)
          })
          if (titles.length > 0) commit('setTitles', { cid: cid, titles: titles })
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
