import { firebase, provider } from '@/firebase'
import router from '@/router'

const state = {
  uid: null
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
   * ユーザーIDのセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   */
  setUID: (state, uid) => {
    state.uid = uid
  }
}

const actions = {
  /**
   * ユーザー情報が更新されたら取得する
   */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      // アカウント作成かどうか
      const isSingup = sessionStorage.getItem('signup')

      if (auth) {
        // ユーザー情報の取得
        const user = await dispatch('users/getUser', auth.uid, { root: true })
        if (user) {
          // アカウント作成の場合
          if (isSingup) {
            // セッション情報の削除
            sessionStorage.removeItem('signup')

            // ログアウトして、ログイン画面に遷移させる。
            await dispatch('signout')
            commit('setRedirectURL', '/login', { root: true })
            commit('setTelop', { show: true, msg: 'アカウントが存在します。\nログインしてください。', type: 'warning' }, { root: true })
            router.push({ name: 'login' })
            return
          }
        } else {
          // アカウント作成の場合
          if (isSingup) {
            // セッション情報の削除
            sessionStorage.removeItem('signup')

            // アカウントの作成
            await dispatch('users/createUser', auth.uid, { root: true })

            // ホーム画面に遷移
            commit('setRedirectURL', '/', { root: true })
            commit('setTelop', { show: true, msg: 'アカウントを作成しました。', type: 'success' }, { root: true })
            router.push({ name: 'home' })
          } else {
            // ログアウト
            await dispatch('signout')

            // アカウント作成画面へ移動
            commit('setTelop', { show: true, msg: 'アカウントが存在しません\nアカウントを作成してください', type: 'warning' }, { root: true })
            router.push({ name: 'signup' })
            return
          }
        }

        // 初回に取得したい情報を取得する
        await Promise.all([
          dispatch('desires/getDesires', auth.uid, { root: true }),
          dispatch('rooms/getRooms', auth.uid, { root: true })
        ])
      }

      const redirectPath = rootGetters.redirectPath
      const isFirstAccess = rootGetters.isFirstAccess

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合はリダイレクトさせる
      if (isFirstAccess) router.push({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * サインイン
   * @param {AuthContext} context 認証に使うcontext情報
   * @return {void}
   */
  signin: context => {
    try {
      firebase.auth().signInWithRedirect(provider.google)
      return
    } catch (error) {
      // エラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   * @return {Object} サインアウト成功の有無
   */
  signout ({ commit }) {
    return new Promise(resolve => {
      firebase.auth().signOut().then(() => {
        return resolve({ status: 'success' })
      }).catch(() => {
        router.push({ name: 'error' })
        return resolve({ status: 'error' })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
