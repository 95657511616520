<template>
  <div class="processing">
    <v-progress-circular indeterminate  :width="7" :size="70" color="orange lighten-1"></v-progress-circular>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.processing {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $processing_zindex;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
}
</style>
