import { firebase } from '@/firebase'
import * as CommonFunction from '@/lib/commonFunction'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  /**
   * @param {Object} payload 引数
   * @param {String} payload.path 画像を格納するパス
   * @param {Blob} payload.blob アップロードするBlobファイル
   * @param {Object} payload.customMetadata アップロード時に付与するカスタムメタデータ（設定しない場合はnull）
   * @return {String} アップロード先のURL
   */
  uploadBlob ({ commit }, payload) {
    return new Promise(resolve => {
      const ref = firebase
        .storage()
        .ref()
        .child(
          payload.path +
            CommonFunction.getUniqueStr() +
            '.' +
            payload.blob.type.replace('image/', '')
        )

      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: payload.blob.type,
        customMetadata: payload.customMetadata
      }

      ref
        .put(payload.blob, metadata)
        .then(() => {
          ref.getDownloadURL().then(url => {
            resolve(url)
          })
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * アップロードされているファイルの削除
   * @param {String} url 削除したいファイルのURL
   * @return {void}
   */
  deleteFile ({ commit }, url) {
    return new Promise(resolve => {
      firebase
        .storage()
        .refFromURL(url)
        .delete()
        .then(() => {
          resolve()
        })
        .catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
