import { firestore } from '@/firebase'

const state = {
  desires: []
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 欲求情報
   */
  desires: state => state.desires
}

const mutations = {
  /**
   * stateに欲求情報を格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} desire 欲求情報
   */
  setDesire: (state, desire) => {
    state.desires.unshift(desire)
  },
  /**
   * stateに全欲求情報を格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} desires 全欲求情報の配列
   */
  setDesires: (state, desires) => {
    state.desires = desires
  },
  /**
   * stateの全欲求情報から指定されたdidの欲求を削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} did 欲求情報のドキュメントID
   */
  deleteDesire: (state, did) => {
    state.desires = state.desires.filter(desire => desire.did !== did)
  }
}

const actions = {
  /**
   * 欲求情報の作成
   * @param {Object} param ポストパラメータ
   * @return {String} ドキュメントID
   */
  createDesire ({ commit }, param) {
    return new Promise(resolve => {
      firestore.collection('desires').add(param).then(doc => {
        commit('setDesire', Object.assign(param, { did: doc.id }))
        return resolve(doc.id)
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * 指定欲求情報の取得
   * @param {String} did 欲求のドキュメントID
   * @return {Object} 欲求情報
   */
  getDesire ({ commit }, did) {
    return new Promise(resolve => {
      firestore.collection('desires').doc(did).get().then(doc => {
        return resolve(doc.exists ? Object.assign({ did: doc.id }, doc.data()) : null)
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * 全欲求情報の取得
   * @param {String} uid ユーザーID
   * @return {void}
   */
  getDesires ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('desires')
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshot => {
          const desires = []
          snapshot.forEach(doc => {
            desires.push(Object.assign(doc.data(), { did: doc.id }))
          })
          commit('setDesires', desires)
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  },
  /**
   * 欲求情報の削除
   * @param {String} did 欲求情報のドキュメントID
   * @return {void}
   */
  deleteDesires ({ commit }, did) {
    return new Promise(resolve => {
      firestore.collection('desires')
        .doc(did)
        .delete()
        .then(() => {
          commit('deleteDesire', did)
          return resolve()
        }).catch(() => {
          window.location.href = '/error'
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
