<template>
  <header v-if="isDisplayed" class="header">
    <button v-if="isBackBtn" class="header__btn" @click="$router.go(-1)">
      <v-icon :color="isWhiteBtn ? 'white' : 'blue-grey darken-3'">keyboard_backspace</v-icon>
    </button>
    <h2 v-if="title.length > 0" class="header__title">{{ title }}</h2>
  </header>
</template>

<script>
// 表示しないページ名
const NOT_DISPLAY = [
  'lp',
  'home',
  'data',
  'upload',
  'menu',
  'error',
  'notfound'
]

// ボタンが白い色
const WHITE_BTN = [
  'login',
  'signup',
  'delete'
]

/** ヘッダーの詳細
 *  isBack {Boolean} 戻るボタンの有無
 *  title {String} ヘッダーのタイトル
 */
const HEADER_DETAIL = {
  profile: {
    isBack: false,
    title: ''
  },
  profile_edit: {
    isBack: true,
    title: 'プロフィールの編集'
  },
  room: {
    isBack: true,
    title: '共有部屋'
  },
  room_add: {
    isBack: true,
    title: '共有部屋の作成'
  },
  room_enter: {
    isBack: true,
    title: '共有部屋に参加'
  },
  room_theme: {
    isBack: true,
    title: '共有部屋'
  },
  room_list: {
    isBack: true,
    title: '共有部屋の一覧'
  },
  login: {
    isBack: true,
    title: ''
  },
  signup: {
    isBack: true,
    title: ''
  },
  delete: {
    isBack: true,
    title: ''
  }
}

export default {
  data () {
    return {
    }
  },
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplayed () {
      return this.$route.name !== null ? !NOT_DISPLAY.includes(this.$route.name) : false
    },
    /**
     * @return {Boolean} 戻るボタンを表示させるかどうか
     */
    isBackBtn () {
      return HEADER_DETAIL[this.$route.name].isBack
    },
    /**
     * @return {Boolean} 白色のボタンかどうか
     */
    isWhiteBtn () {
      return WHITE_BTN.includes(this.$route.name)
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return HEADER_DETAIL[this.$route.name].title
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: relative;
  width: 100%;
  height: $header_height;
  text-align: left;
  &__btn {
    position: absolute;
    height: $header_height;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
  }
  &__title {
    max-width: 80%;
    margin: 0 auto;
    line-height: $header_height;
    text-align: center;
  }
}
</style>
