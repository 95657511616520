<template>
  <v-app id="app">
    <original-header v-if="!isAuthProcessing" />
    <router-view v-if="!isAuthProcessing"  v-show="!isSubmitting" />
    <original-footer v-if="!isAuthProcessing" />
    <processing v-if="isProcessing || isSubmitting" />
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header'
import OriginalFooter from '@/components/common/footer'
import Processing from '@/components/common/processing'
import Telop from '@/components/common/telop'

export default {
  components: { OriginalHeader, OriginalFooter, Processing, Telop },
  mounted () {
    // 認証後の遷移先として現在のURLを保存する
    this.$store.commit('setRedirectURL', window.location.pathname)

    // authの監視
    this.$store.dispatch('auth/onAuth')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting () {
      return this.$store.getters.isSubmitting
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters['telop']
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

html {
  font-size: 10px !important;
  background-color: white;
}

a {
  color: $color_black;
  text-decoration: none;
  outline: none;
}

#app {
  box-sizing: border-box;
  height: 100vh;
  padding: 0 $padding_wide 0 $padding_wide;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: $color_black;
  text-align: center;
  background-color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: 0;
}

/**
 * vuetifyjs
 */

.v-responsive {
  max-width: 100vw !important;
}

.v-btn--disabled {
  background-color: $color_lighten_gray !important;
}
</style>
