<template>
  <transition name="fade">
    <div
      :class="telop.type === 'success' ? 'telop'
        : telop.type === 'warning' ? 'telop--yellow'
          : telop.type === 'error' ? 'telop--red' : ''">
      <p class="telop__text">{{ telop.msg }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  mounted () {
    // 3秒後にテロップを消す
    setInterval(() => {
      this.$store.commit('setTelop', { show: false, msg: '', type: '' })
    }, 3000)
  },
  computed: {
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters['telop']
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.telop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup_zindex;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 74px;
  background-color: $color_green;
  &--yellow {
    @extend .telop;
    background-color: $color_darken_yellow;
  }
  &--red {
    @extend .telop;
    background-color: $color_red;
  }
  &__text {
    max-width: 75%;
    margin: 0;
    margin-left: 6px;
    font-size: 1.4rem;
    color: white;
    text-align: left;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

// アニメーション用
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
