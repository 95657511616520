<template>
  <v-row class="cards" no-gutters>
    <v-col :class="maslowLine.includes(n) ? 'cards__area--bottom': 'cards__area'"
           v-for="n in cardNum" :key="n" @click="isPositive = !isPositive" cols="6" no-gutters>
      <v-img class="cards__area__img"
             :src="require('@/assets/img/cards/' + ('0' + n).slice(-2)
               + '_' + (isPositive ? 'ポジティブ' : 'ネガティブ') + '.png')" />
    </v-col>
  </v-row>
</template>

<script>
// 欲求カード数
const CARD_NUM = 24
// マズローの5段階欲求の境目にあるカードの番号
const MASLOW_LINE = [3, 4, 7, 8, 13, 14, 17, 18]

export default {
  data () {
    return {
      // ポジティブかどうか
      isPositive: true
    }
  },
  computed: {
    /**
     * @return {Number} 欲求カード数
     */
    cardNum () {
      return CARD_NUM
    },
    /**
     * @return {Array} マズローの5段階欲求の境目にあるカードの番号
     */
    maslowLine () {
      return MASLOW_LINE
    }
  }
}
</script>

<style scoped lang="scss">
.cards {
  width: 100%;
  border: 4px solid black;
  &__area {
    box-sizing: border-box;
    &--bottom {
      @extend .cards__area;
      border-bottom: 4px solid black;
    }
  }
}
</style>
