<template>
  <v-layout v-if="isDisplay" align-center justify-space-around class="footer">
    <div class="footer__menu" @click="$router.push({ name: 'home' })">
      <v-icon class="footer__menu__icon"
              :color="isPage('home') ? 'orange darken-2' : ''">home</v-icon>
    </div>
    <div class="footer__menu"
         @click="isLoggedin ? $router.push({ name: 'data' }) : $router.push({ name: 'login' })">
      <v-icon class="footer__menu__icon"
              :color="isPage('data') ? 'orange darken-2' : ''">assessment</v-icon>
    </div>
    <div class="footer__menu"
         @click="isLoggedin ? $router.push({ name: 'upload' }) : $router.push({ name: 'login' })">
      <v-icon class="footer__menu__icon"
              :color="isPage('upload') ? 'orange darken-2' : ''">add_box</v-icon>
    </div>
    <div class="footer__menu"
         @click="isLoggedin ? $router.push({ name: 'profile' }) : $router.push({ name: 'login' })">
      <v-icon class="footer__menu__icon"
              :color="isPage('profile') ? 'orange darken-2' : ''">account_circle</v-icon>
    </div>
    <div class="footer__menu" @click="$router.push({ name: 'menu' })">
      <v-icon class="footer__menu__icon"
              :color="isPage('menu') ? 'orange darken-2' : ''">menu</v-icon>
    </div>
  </v-layout>
</template>

<script>
// フッターを表示させないページ名
const NOT_DYSPLAY = [
  'lp',
  'login',
  'signup',
  'delete',
  'error',
  'notfound'
]

export default {
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return this.$route.name !== null ? !NOT_DYSPLAY.includes(this.$route.name) : false
    },
    /**
     * @return {Boolean} ログイン済みかどうか
     */
    isLoggedin () {
      const uid = this.$store.getters['auth/uid']
      return uid && this.$store.getters['users/user'](uid)
    }
  },
  methods: {
    /**
     * @param {String} page ページ名
     * @return {Booelan} 表示中のページが引数名のページかどうか
     */
    isPage (page) {
      return this.$route.name === null ? false : this.$route.name.indexOf(page) === 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/fontfamily.scss';
@import '@/assets/sass/size.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: $footer_height;
  background-color: rgba(255, 255, 255, 0.9);
  &__menu {
    &__icon {
      font-family: $material_outlined;
    }
  }
}
</style>
