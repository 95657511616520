<template>
  <div class="home" v-if="!isProcessing">
    <cards />
  </div>
</template>

<script>
import cards from '@/components/common/cards'

export default {
  components: { cards },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  padding-bottom: $footer_height;
  margin-left: -$padding_wide;
}
</style>
