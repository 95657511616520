import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/lp',
      name: 'lp',
      component: loadComponent('LP.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/data',
      name: 'data',
      component: loadComponent('Data.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/upload',
      name: 'upload',
      component: loadComponent('Upload.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit',
      name: 'profile_edit',
      component: loadComponent('ProfileEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/room/add',
      name: 'room_add',
      component: loadComponent('RoomAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/room/enter',
      name: 'room_enter',
      component: loadComponent('RoomEnter.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/room/list',
      name: 'room_list',
      component: loadComponent('RoomList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/room/:rid',
      name: 'room',
      component: loadComponent('Room.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/room/:rid/theme/:tid',
      name: 'room_theme',
      component: loadComponent('RoomTheme.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: loadComponent('Signup.vue')
    },
    {
      path: '/delete',
      name: 'delete',
      component: loadComponent('Delete.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('Notfound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ]
})

/**
 * コンポーネントの取得
 * @param {String} name コンポーネントの名前
 * @return {String} パス名
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isSP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  )

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const user = uid ? store.getters['users/user'](uid) : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  if (isAuthProcessing || autoTransition) {
    // 初回アクセス時は無限ループ
    // 無条件で遷移されるページの場合は、そのまま遷移
    if (autoTransition) next()
  } else if (!isSP) {
    to.name !== 'lp' ? next({ name: 'lp' }) : next()
  } else if (!user) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth || ['home', 'menu'].includes(to.name) ? next() : next({ name: 'home' })
  }
})

export default router
