/**
 * 共通関数
 * [利用方法]
 * import * as CommonFunction from '@/lib/commonFunction'
 */

import moment from 'moment'

/**
 * スマートフォンかどうか
 * @return {Boolean}
 */
export const isSP = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  )
}

/**
 * Date型を文字列に変換
 * @param {Date} date Date型の日付
 * @param {String} format YYYY:MM:DDなどのフォーマット
 * @return {String} 変換後の文字列
 */
export const getTimeString = (date, format) => {
  return moment(date).format(format)
}

/**
 * @return {String} ユニークな文字列
 */
export const getUniqueStr = () => {
  return new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)
}

/**
 * @param {Base64} base64 base64のファイルデータ
 * @param {String} fileType ファイルタイプ
 * @return {Blob} Blobファイル
 */
export const base64ToBlob = (base64, fileType) => {
  const bin = atob(base64.replace(/^.*,/, ''))
  const buffer = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  return new Blob([buffer.buffer], {
    type: fileType || 'image/png'
  })
}
