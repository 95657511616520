import Vue from 'vue'
import Vuetify from 'vuetify'
import VueGtag from 'vue-gtag'
import VueQriously from 'vue-qriously'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store/'
import './registerServiceWorker'

import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

/**
 * setting of vuetify which is material design lib
 * @see https://vuetifyjs.com
 */
const opts = {}
Vue.use(Vuetify, { iconfont: 'md' })

/**
 * @see https://www.npmjs.com/package/vue-qriously
 */
Vue.use(VueQriously)

/**
 * setting of vue analytics
 * @see https://github.com/MatteoGabriele/vue-gtag
 */
Vue.use(VueGtag, {
  config: { id: 'UA-147446378-1' }
})


/**
 * @see https://github.com/Inndy/vue-clipboard2
 */
Vue.use(VueClipboard)

// 認証処理が走るので最初はtrueに
store.commit('setProcessing', true)

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  render (h) {
    return h(App)
  }
}).$mount('#app')
