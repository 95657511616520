import { firestore } from '@/firebase'

const state = {
  users: [] // [ { uid: '', user: {} }, ... ]
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users.filter(user => user.uid === uid).length === 1 ? state.users.filter(user => user.uid === uid)[0] : null
}

const mutations = {
  /**
   * ユーザー情報のセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {Object} pyaload.user ユーザー情報
   */
  setUser: (state, payload) => {
    const users = state.users.filter(user => user.uid !== payload.uid)
    users.push(Object.assign({ uid: payload.uid }, payload.user))
    state.users = users
  }
}

const actions = {
  /**
   * ユーザー情報の作成
   * @param {String} uid ユーザーID
   * @return {void}
   */
  createUser ({ dispatch }, uid) {
    const user = {
      name: null,
      icon: null,
      sex: null,
      birthday: null,
      from: null,
      occupation: null,
      salary: null,
      hobby: null,
      authority: 'member',
      createdAt: new Date(),
      updatedAt: new Date()
    }
    return new Promise(resolve => {
      firestore.collection('users').doc(uid).set(user).then(async () => {
        await dispatch('getUser', uid)
        return resolve()
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * ユーザー情報の取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報。存在しない場合はnull
   */
  getUser ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('users').doc(uid).get().then(doc => {
        if (doc.exists) {
          commit('setUser', { uid: uid, user: doc.data() })
          return resolve(doc.data())
        } else {
          return resolve(null)
        }
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * ユーザー情報の更新
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.params ポストパラメータ
   * @return {void}
   */
  updateUser ({ commit }, payload) {
    return new Promise(resolve => {
      firestore.collection('users').doc(payload.uid).update(payload.params).then(() => {
        commit('setUser', { uid: payload.uid, user: payload.params })
        return resolve()
      }).catch(() => {
        window.location.href = '/error'
      })
    })
  },
  /**
   * ユーザー情報の削除
   * @param {String} uid ユーザーID
   * @return {void}
   */
  deleteUser ({ commit }, uid) {
    return new Promise(resolve => {
      firestore.collection('users').doc(uid).delete().then(() => {
        return resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
